<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="updatePassword">
      <b-row class="mb-2">
        <b-col md="12">
          <h4 class="mb-1">
            Invite
          </h4>
          <b-form-group
            label="Enter the email of the person or organization for the referral"
            label-for="referEmail"
          >
            <b-input-group class="d-flex ">
              <b-form-input
                id="referEmail"
                v-model="referEmail"
                name="refer"
                type="email"
                placeholder="Enter Email Address"
              />
              <b-button
                variant="primary"
                class="ml-1"
              >
                Submit
              </b-button>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <h4 class="mb-1">
            Share the referral link
          </h4>
          <b-form-group
            label="Share the referral link"
            label-for="referLink"
          >
            <b-input-group class="d-flex ">
              <b-form-input
                id="referLink"
                v-model="referLink"
                name="refer"
                type="link"
                placeholder="https://starworks.com/referral"
              />
              <b-button
                variant="outline-primary"
                class="ml-1"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Auth } from 'aws-amplify'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      referEmail: '',
      referLink: '',
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
  },
  methods: {
    updatePassword() {
      Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, this.passwordValueOld, this.newPasswordValue))
        .then(data => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data === 'SUCCESS' ? 'Password updated successfully' : 'Failed to update',
              icon: data === 'SUCCESS' ? 'CheckIcon' : 'XIcon',
              variant: data === 'SUCCESS' ? 'success' : 'danger',
            },
          })
          this.passwordValueOld = ''
          this.newPasswordValue = ''
          this.RetypePassword = ''
        })
        .catch(err => this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: 'XIcon',
            variant: 'danger',
          },
        }))
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
