<template>
  <b-overlay :show="$apollo.loading">
    <b-card>
      <!-- form -->
      <b-form @submit.prevent="updateUser">
        <b-row>
          <b-col sm="12">
            <b-form-group
              label="Industry"
              label-for="industry"
            >
              <v-select
                v-model="industryArray"
                multiple
                :options="industryList"
                label="Industry"
                :placeholder="'Select industry(s)'"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Startup Stage"
              label-for="startupStage"
            >
              <v-select
                v-model="startupStageArray"
                multiple
                :options="startupStageList"
                label="Startup Stage"
                :placeholder="'Select an startup lifecycle stage(s)'"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              type="submit"
              variant="primary"
              class="mt-2 mr-1 d-flex align-items-center"
            >
              <b-spinner
                v-if="loading"
                small
                class="mr-50"
              />
              Save Changes
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BCol, BForm, BFormGroup, BRow, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BSpinner,
    BCard,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      loading: false,
      profileFile: null,
      userData: {
        industry: '',
        startupStage: '',
      },
      industryList: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      startupStageList: ['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling'],
    }
  },
  computed: {
    industryArray: {
      get() {
        // Convert the comma-separated string to an array
        return this.userData.industry ? this.userData.industry?.split(',') : []
      },
      set(value) {
        this.userData.industry = value.join(',')
      },
    },
    startupStageArray: {
      get() {
        return this.userData.startupStage ? this.userData.startupStage.split(',') : []
      },
      set(value) {
        this.userData.startupStage = value.join(',')
      },
    },
  },
  methods: {
    updateUser() {
      this.loading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_users_customuser_by_pk(pk_columns: {id: ${getUserData().id}}, _set: {industry: "${this.userData.industry}", startupStage: "${this.userData.startupStage}"}) {
            id
          }
        }`,
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.userData.refetch()
          this.loading = false
        },
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.loading = false
        })
    },
  },
  apollo: {
    userData: {
      query: gql`
          {
            users_customuser_by_pk(id: ${getUserData().id}) {
              id
              industry
              startupStage
            }
          }`,
      update(data) {
        return {
          industry: data.users_customuser_by_pk.industry,
          startupStage: data.users_customuser_by_pk.startupStage,
        }
      },
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
